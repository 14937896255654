import NextHead from "next/head";
import Image from "next/image";
import FrameMeta from "./FrameMeta";

type Props = {
  title?: string;
  frame?: object;
  insightTracking?: boolean;
  showDownloadBanner?: boolean;
};

export default function Head({
  title = "Bunches",
  frame = undefined,
  insightTracking = false,
  showDownloadBanner = false,
}: Props) {
  return (
    <>
      <NextHead>
        <title key="title">{title}</title>
        <meta
          name="description"
          key="description"
          content="Chat sports all day on Bunches. Analyze plays and share your opinion. Rant and rave on the latest trades. Comment on moments as they happen."
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content="https://onbunches.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Chat sports all day on Bunches. Analyze plays and share your opinion. Rant and rave on the latest trades. Comment on moments as they happen."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onbunches" />
        <meta name="twitter:domain" content="onbunches.com" />
        {!frame && (
          <>
            <meta property="og:title" content="Bunches" />
            <meta property="og:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />
            <meta name="twitter:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />
            <meta name="twitter:url" content="https://onbunches.com" />
            <meta name="twitter:title" content="On Bunches" />
          </>
        )}

        <link rel="canonical" href="https://onbunches.com" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Bunches",
            "url": "https://onbunches.com"
          }
        `,
          }}
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            // load Branch
            (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode".split(" "), 0);
            // init Branch
            branch.init('key_live_ahSqHxz4A4AFEl3RTzSzQojiByeDlvgD');
          `,
          }}
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            <!-- Reddit Pixel -->
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_8tq983fx');rdt('track', 'PageVisit');
          `,
          }}
        ></script>

        {insightTracking && <script async src="https://dujantdza7z0f.cloudfront.net/Insight/Insight.js" />}
        {showDownloadBanner && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              branch.banner({
                icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/d6/d9/0f/d6d90f00-ff02-ec74-eea1-f0be2a6dfaf0/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp',
                title: 'Bunches',
                description: 'Chat Sports&#8482;'
              }, {});
            `,
            }}
          ></script>
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: `
              <!-- Twitter Pixel -->
              !function(e,t,n,s,u,a)
              {e.twq ||
                ((s = e.twq =
                  function () {
                    s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
                  }),
                (s.version = "1.1"),
                (s.queue = []),
                (u = t.createElement(n)),
                (u.async = !0),
                (u.src = "https://static.ads-twitter.com/uwt.js"),
                (a = t.getElementsByTagName(n)[0]),
                a.parentNode.insertBefore(u, a))}
                (window,document,'script'); twq('config','olb02');
                `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              <!-- Meta Pixel Code -->
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1262066573946813');
              fbq('track', 'PageView');
              `,
          }}
        ></script>
        <noscript>
          <Image
            height="1"
            width="1"
            style={{ display: "none" }}
            src={"https://www.facebook.com/tr?id=1262066573946813&ev=PageView&noscript=1"}
            alt=""
          />
        </noscript>
      </NextHead>
      {frame && <FrameMeta frame={frame} />}
    </>
  );
}
